import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { Button, Popover, PopoverContent, PopoverTrigger } from '@antcorefinance/ui';
import { JazzIcon } from '@antcorefinance/ui/icons/JazzIcon';
import { useBaseTokens } from 'lib/common/use-base-tokens';
import { useTokenBalance } from 'lib/common/use-token-balances';
import React, { useState, useEffect } from 'react';
import { ConnectButton } from './connect-button';
import { ConnectView } from './connect-view/connect-view';
import { DefaultView } from './default-view';
import { SettingsView } from './settings-view';
import { fetchUserData } from '@/components/events';
// import { Circle } from "lucide-react";
// Mock function to fetch user data (replace with actual API call)

export enum ProfileView {
  Disconnected = 0,
  Default = 1,
  Transactions = 2,
  Settings = 3,
}

export const factions = [
  {
    id: 1,
    name: 'Red Swarm',
    color: 'text-red-600',
    bgcolor: 'bg-red-600',
    description: 'Unite under the Red Swarm banner.',
  },
  {
    id: 2,
    name: 'Blue Nest',
    color: 'text-blue-600',
    bgcolor: 'bg-blue-600',
    description: 'Organize and strategize with the Blue Nest.',
  },
  {
    id: 3,
    name: 'Purple Hive',
    color: 'text-purple-600',

    bgcolor: 'bg-purple-600',
    description: 'Innovate and adapt within the Purple Hive.',
  },
];

export function UserProfile() {
  const { account, connected } = useWallet();
  const [view, setView] = useState<ProfileView>(ProfileView.Default);
  const { data: tokens } = useBaseTokens();
  const [userData, setUserData] = useState({ factionId: null, userPoints: 0 });

  const nativeCurr = tokens?.['0x1::aptos_coin::AptosCoin'];
  const { data: balance } = useTokenBalance({
    account: account?.address,
    currency: nativeCurr?.address,
    refetchInterval: 2000,
  });

  // const factionName =
  //   factions.find((f) => f.id === userData.factionId)?.name || "";

  const currentFaction = factions.find((f) => f.id === userData.factionId);
  const factionName = currentFaction?.name || '';
  const factionColor = currentFaction?.color || 'text-gray-600'; // Default to gray if no faction is found

  useEffect(() => {
    let intervalId: any;

    if (account?.address) {
      // Fetch user data when the component mounts or the account changes
      fetchUserData(account.address).then((data: any) => setUserData(data));

      // Set up an interval to fetch data every 3 seconds
      intervalId = setInterval(() => {
        fetchUserData(account.address).then((data: any) => setUserData(data));
      }, 3000);
    }

    // Clean up the interval on component unmount or when the account changes
    return () => clearInterval(intervalId);
  }, [account?.address]);

  if (!connected || !account?.address) return <ConnectButton variant="outline" />;

  return (
    <Popover>
      {/* <p className={`text-sm  `}>Your Pts: {userData.userPoints}</p> */}

      <PopoverTrigger asChild>
        <Button variant="secondary">
          <>
            <div className="hidden md:flex">
              <div className="flex flex-row">
                {/* <p
                  style={{ marginRight: "2px", zIndex: "0" }}
                  className={`text-sm  w-5 h-5 rounded-full ${factionColor} `}
                >
                  <Circle> </Circle>
                </p> */}
                <JazzIcon diameter={20} address={account?.address} />
              </div>
            </div>

            <div className="flex flex-row gap-2">
              {/* <p className="text-sm"> {factionName}</p> */}
              <p className={factionColor}>
                {' '}
                {`${account?.address?.substring(
                  0,
                  5,
                )}...${account?.address?.substring(66 - 3)}`}{' '}
              </p>
            </div>
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80" onOpenAutoFocus={(e) => e.preventDefault()}>
        {!connected && <ConnectView close={close} />}
        {connected && view === ProfileView.Default && (
          <DefaultView
            faction={userData.factionId}
            userPoints={userData.userPoints}
            balance={balance && balance / 10 ** 8}
            setView={setView}
          />
        )}
        {view === ProfileView.Settings && <SettingsView setView={setView} />}
      </PopoverContent>
    </Popover>
  );
}
